App.Components.Modal = class Modal {

	constructor(element, options) {
		this.element = element;
		this.options = options;
		this.modalContainer = document.querySelector("#modals"),
		this.modals = this.modalContainer.querySelectorAll(".modal"),
		this.modalLinks = document.querySelectorAll("div[data-modal], a[data-modal], button[data-modal]");
		this.bindEvents();
	}

	bindEvents() {
		let self = this;

		// Hook up modal close event on modal-container
		this.modalContainer.addEventListener("click", function (e) {
			let target = e.target || e.toElement;
			let parent = e.target.parentElement;

			if (target != self.modalContainer &&
				(!target.classList.contains("_close") && (!parent.classList.contains("_close"))))
			{
				return;
			}

			e.preventDefault();

			self.modalContainer.classList.remove("-active");
			self.modalContainer.querySelector(".-active").classList.remove("-active");
			self.modalContainer.classList.remove("-light");
			document.querySelector("body").classList.remove("-no-scroll");
		});

		// Hook up events for modal windows
		[].map.call(this.modalLinks, function(link) {
			link.addEventListener("click", function (e) {
				e.preventDefault();

				if (this.parentElement.classList.contains("-active"))
					return;

				let modal = document.querySelector(link.dataset.modal);
				self.showModal(modal);

				if (link.dataset.focus != null) {
					let focusElement = modal.querySelector(link.dataset.focus);
					focusElement.focus();
					focusElement.select();
				}

			});
		});
	}

	showModal(modal) {
		if (modal.classList.contains("-light")) {
			this.modalContainer.classList.add("-light");
		}
		this.modalContainer.classList.add("-active");
		modal.classList.add("-active");
		document.querySelector("body").classList.add("-no-scroll");
	}
};