/*
	Entry point to our web site / application.
*/
var App = (function () {

	"use strict";

	/*
	 * Environment Details
	 */
	var debug = true,
		jQueryInUse = !(typeof jQuery == "undefined"),
		jQueryVersion = (jQueryInUse ? jQuery.fn.jQuery : null),
		modernizrInUse = !(typeof Modernizr == "undefined"),
		modernizrVersion = (modernizrInUse ? Modernizr._version : null),
		location = window.location;

	if (debug) {
		console.log("INITIALIZATION STATUS:")
		console.log("Location: " + location.pathname);
		console.log("jQuery: " + jQueryInUse + " " + jQueryVersion)
		console.log("Modernizr:" + modernizrInUse + " " + modernizrVersion)
	}

	/*
	 * Execute scripts on page ready and window load
	 */
	var _scriptsOnReady = [],
        _scriptsOnLoad = [];


	document.addEventListener("DOMContentLoaded", function (e) {
		console.log("Calling scripts queued for document ready.")
		executeScripts(_scriptsOnReady);
	});

	window.addEventListener("load", function (e) {
		console.log("Calling scripts queued for window load.")
		executeScripts(_scriptsOnLoad);
	});

	/*
     * Private methods
     */
	function executeScripts(_scripts) {
		if (_scripts) {
			_scripts.forEach(function (_script) {
				// execute script()
				_script();
			});
		}
		console.log("Scripts have been executed.");
	};

	/*
	 * Public methods
	 */
	function queue(delay, order, script) {
		// delay: 0 = execute immediately, 1 = wait until page load
		delay = typeof delay !== "undefined" ? delay : 0;
		// order: int = position in queue
		order = typeof order !== "undefined" ? order : 0;
		// script: function to execute
		script = typeof script !== "undefined" ? script : function () { };

		if (delay === 0) {
			// execute script() immediately
			script();
		} else if (delay === 1) {
			// execute script() on page ready
			_scriptsOnReady.push(script);
		} else if (delay === 2) {
			// execute script() on page load
			_scriptsOnLoad.push(script);
		} else {
			// queue was called with an incorrect value for delay
			console.log("App.queue() was called with an improper value for delay (" + delay + ")");
		}
	};

	function performance() {
	};

	function storage() {
	};

	/*
	 *	Reveal public methods
	 */
	return {
		queue: queue,
		performance: performance,
		storage: storage,
		Components: {}
	};

})();
