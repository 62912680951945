App.Components.Accordion = class Accordion {

	constructor(element, options) {
		this.element = element;
		this.options = options;
		this.accordions = document.querySelectorAll(".accordion"),
		this.accordionHeaders = document.querySelectorAll(".accordion ._toggle");

		this.bindEvents();
	}

	bindEvents() {
		let self = this;

		// Hook up events for accordions
		[].map.call(this.accordionHeaders, function (accordionHeader) {
			accordionHeader.addEventListener("click", function (e) {

				if (this.parentElement.classList.contains("-active"))
					return;

				self.showPanel(accordionHeader.parentElement);
			});
		});

	}

	showPanel(accordionElement) {
		let parentElement = accordionElement.parentElement;
		let isGrouped = parentElement.classList.contains("accordions");

		// Set active accordion
		if (isGrouped) {
			[].map.call(this.accordions, function (accordion) {
				if (accordion.parentElement == parentElement) {
					accordion.classList.remove("-active");
				}
			});
		}
		accordionElement.classList.toggle("-active");

	}
}