App.Components.Carousel = class Carousel {

	constructor(element, options) {
		this.element = element;
		this.options = options;
		this.stage = element.querySelector(".stage");
		this.scenes = element.querySelectorAll(".stage > div");
		this.next = element.querySelector(".next");
		this.previous = element.querySelector(".previous");
		this.currentScene = 1;
		this.lastScene = this.scenes.length;

		// Reference to timers, intervals, and events
		this.timers = [];
		this.intervals = [];
		this.events = [];

		// Configure options (1) passed in, (2) data attributes, (3) defaults
		if (!this.options)
			this.options = {};
		this.options.defaultShowTime = 7000;
		this.options.autoplay = true;
		this.options.continuous = true;

		// Setup events
		this.bindEvents();
	}

	bindEvents() {
		let self = this;

		// Manual next event
		this.next.addEventListener("click", function (e) {
			self.gotoNextScene();
			self.options.autoplay = false;
			[].map.call(self.intervals, function (interval) {
				window.clearInterval(interval);
			});
		});

		// Manual previous event
		this.previous.addEventListener("click", function (e) {
			self.gotoPreviousScene();
			self.options.autoplay = false;
			[].map.call(self.intervals, function (interval) {
				window.clearInterval(interval);
			});
		});

		// Autoplay
		if (this.options.autoplay) {
			this.intervals.push(window.setInterval(self.gotoNextScene.bind(this), this.options.defaultShowTime));
		}

	}

	gotoNextScene() {
		console.log("gotoNextScene called")

		if (this.currentScene == this.lastScene) {

			if (!this.options.continuous) {
				return;
			} else {
				this.currentScene = 1;
				this.stage.style.transform = "translateX(0)";
				return;
			}
		}

		this.stage.style.transform = "translateX(-" + ((this.currentScene - 1) * 100 + 100) + "%)";
		this.currentScene++;
	}

	gotoPreviousScene() {
		console.log("gotoPreviousScene called")

		if (this.currentScene == 1) {
			return;
		}

		this.currentScene--;
		this.stage.style.transform = "translateX(-" + ((this.currentScene - 1) * 100) + "%)";

	}
}